<template xmlns:div="http://www.w3.org/1999/html">
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" data-vv-scope="frmTierPadrao">
    <div class="row justify-content-md-center">
      <!-- FCL -->
      <div class="col-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.tierLocal.tipoCargaList[3]') }}</h5>
            <!-- FLC EXPO -->
            <div class="row">
              <!-- Frete -->
              <div class="col-12 col-sm-6 form-group mb-4">
                <label>{{ this.$i18n.t('form.cad_pessoa.tier_frete_expo') }}</label>
                <v-select
                  label="code"
                  name="formData.tierFreteExpo"
                  class="md-form-control"
                  :clearable="true"
                  :searchable="true"
                  :options="tierFreteList"
                  v-model="formData.tierFreteExpo"
                  v-validate="{ required: false }"
                >
                  <template slot="no-options">
                    {{ 'Nenhum resultado encontrado' }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.code }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.code }}
                    </div>
                  </template>
                </v-select>
                <transition name="slide" mode="">
                  <small v-if="errors.first('formData.tierFreteExpo')" class="royalc-error-field">{{ errors.first('formData.tierFreteExpo') }}</small>
                </transition>
              </div>
              <!-- Locais -->
              <div class="col-12 col-sm-6 form-group mb-4">
                <label>{{ this.$i18n.t('form.cad_pessoa.tier_local_expo') }}</label>
                <v-select
                  label="codigo"
                  class="md-form-control"
                  name="formData.tierLocalExpo"
                  :clearable="true"
                  :searchable="true"
                  :options="getTiersLocaisPorOperacaoExpo"
                  v-model="formData.tierLocalExpo"
                  v-validate="{ required: false }"
                  >
                  <template slot="no-options">
                    {{ 'Nenhum resultado encontrado' }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.codigo }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.codigo }}
                    </div>
                  </template>
                </v-select>
                <transition name="slide" mode="">
                  <small v-if="errors.first('formData.tierLocalExpo')" class="royalc-error-field">{{ errors.first('formData.tierLocalExpo') }}</small>
                </transition>
              </div>
            </div>
            <!-- FCL IMPO -->
            <div class="row">
              <!-- Frete -->
              <div class="col-12 col-sm-6 form-group mb-4">
                <label>{{ this.$i18n.t('form.cad_pessoa.tier_frete_impo') }}</label>
                <v-select
                  label="code"
                  class="md-form-control"
                  name="formData.tierFreteImpo"
                  :clearable="true"
                  :searchable="true"
                  :options="tierFreteList"
                  v-model="formData.tierFreteImpo"
                  v-validate="{ required: false }"
                >
                  <template slot="no-options">
                    {{ 'Nenhum resultado encontrado' }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.code }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.code }}
                    </div>
                  </template>
                </v-select>
                <transition name="slide" mode="">
                  <small v-if="errors.first('formData.tierFreteImpo')" class="royalc-error-field">{{ errors.first('formData.tierFreteImpo') }}</small>
                </transition>
              </div>

              <!-- Locais -->
              <div class="col-12 col-sm-6 form-group mb-4">
                <label>{{ this.$i18n.t('form.cad_pessoa.tier_local_impo') }}</label>
                <v-select
                  label="codigo"
                  class="md-form-control"
                  name="formData.tierLocalImpo"
                  :clearable="true"
                  :searchable="true"
                  :options="getTiersLocaisPorOperacaoImpo"
                  v-model="formData.tierLocalImpo"
                  v-validate="{ required: false }"
                >
                  <template slot="no-options">
                    {{ 'Nenhum resultado encontrado' }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.codigo }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.codigo }}
                    </div>
                  </template>
                </v-select>
                <transition name="slide" mode="">
                  <small v-if="errors.first('formData.tierLocalImpo')" class="royalc-error-field">{{ errors.first('formData.tierLocalImpo') }}</small>
                </transition>
              </div>
            </div>
          </div>
         </div>
      </div>
      <!-- LCL EXPO -->
      <div class="col-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.tierLocal.tipoCargaList[4]') }} Expo</h5>
            <!-- LCL EXPO -->
            <div class="row">
              <!-- Frete -->
              <div class="col-12">
                <label>{{ this.$i18n.t('form.cad_pessoa.tier_frete') }}</label>
                <form-tier-range :tier="tierLCL" v-for="(tierLCL, index) in formData.tierFreteLclExpo" v-bind:key="index" />
              </div>

              <!-- Locais -->
              <div class="col-12 col-sm-6 form-group mt-4  mb-4">
                <label>{{ this.$i18n.t('form.cad_pessoa.tier_local_expo') }}</label>
                <v-select
                  label="codigo"
                  class="md-form-control"
                  :clearable="true"
                  :searchable="true"
                  :options="getTiersLocaisLclPorOperacaoExpo"
                  v-model="formData.tierLocalLclExpo"
                  name="formData.tierLocalLclExpo"
                  v-validate="{ required: false }"
                >
                  <template slot="no-options">
                    {{ 'Nenhum resultado encontrado' }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.codigo }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.codigo }}
                    </div>
                  </template>
                </v-select>
                <transition name="slide" mode="">
                  <small v-if="errors.first('formData.tierLocalLclExpo')" class="royalc-error-field">{{ errors.first('formData.tierLocalLclExpo') }}</small>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- LCL IMPO -->
      <div class="col-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.tierLocal.tipoCargaList[4]') }} Impo</h5>
            <div class="row">
              <!-- Frete -->
              <div class="col-12">
                <label>{{ this.$i18n.t('form.cad_pessoa.tier_frete') }}</label>
                <form-tier-range :tier="tierLCL" v-for="(tierLCL, index) in formData.tierFreteLclImpo" v-bind:key="index" />
              </div>

              <!-- Locais -->
              <div class="col-12 col-sm-6 form-group mt-4  mb-4">
                <label>{{ this.$i18n.t('form.cad_pessoa.tier_local_impo') }}</label>
                <v-select
                  label="codigo"
                  class="md-form-control"
                  :clearable="true"
                  :searchable="true"
                  :options="getTiersLocaisLclPorOperacaoImpo"
                  v-model="formData.tierLocalLclImpo"
                  name="formData.tierLocalLclImpo"
                  v-validate="{ required: false }"
                >
                  <template slot="no-options">
                    {{ 'Nenhum resultado encontrado' }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.codigo }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.codigo }}
                    </div>
                  </template>
                </v-select>
                <transition name="slide" mode="">
                  <small v-if="errors.first('formData.tierLocalLclImpo')" class="royalc-error-field">{{ errors.first('formData.tierLocalLclImpo') }}</small>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!-- Submit -->
      <div class="col-12">
        <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">
          Salvar
        </button>
        <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">
          Cancelar
        </button>
      </div>
    </form>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import formTierRange from '@/views/Schedules/TierSettings/formTierRange'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import TierService from '@/services/TierService'

export default {
  name: 'TierSettingsForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.tier_settings') + ' - %s'
    }
  },
  data () {
    return {
      receivedObject: {},
      tiersFreteLCLExpoList: [],
      tiersFreteLCLImpoList: [],
      destinations: [],
      formErrors: {},
      isLoading: true,
      fullPage: true,
      // List of options to select on select input
      tierFreteList: [],
      tierLocalList: [],
      emptyLocalTierOption: {
        id: null,
        codigo: 'Não exibir'
      },
      //  Selected Values (Form)
      formData: {
        tierFreteExpo: null,
        tierFreteImpo: null,
        tierFreteLclExpo: [],
        tierFreteLclImpo: [],
        tierLocalExpo: null,
        tierLocalImpo: null,
        tierLocalLclExpo: null,
        tierLocalLclImpo: null
      },
      submitted: false

    }
  },
  components: {
    formTierRange,
    Loading,
    vSelect
  },
  // Apply filters to local filter
  created () {
    let _this = this
    _this.isLoading = true
    // Load basilares data

    let filters = {
      'per_page': 1000
    }

    let promises = [
      TierService.getTiersLocais(filters),
      TierService.getTiers(filters)
    ]

    // If is edit populate values
    let id = this.$route.params.id

    if (id) {
      promises.push(TierService.getTierCustomer(id))
    }

    Promise.all(promises).then((values) => {
      // Tiers Locais
      let res = values[0]
      _this.tierLocalList = res.data.data
      _this.tierLocalList.unshift(_this.emptyLocalTierOption)

      // Tiers
      res = values[1]
      _this.tierFreteList = res.data.data.filter((item) => {
        return item.tipoCarga === 3
      })

      _this.tiersFreteLCLExpoList = res.data.data.filter((item) => {
        return item.tipoCarga === 4
      })

      _this.tiersFreteLCLImpoList = res.data.data.filter((item) => {
        return item.tipoCarga === 4
      })

      // Edit Info
      if (id) {
        let tier = values[2].data
        this.receivedObject = tier

        _this.formData = {
          tierFreteExpo: tier.tierFreteExpo,
          tierFreteImpo: tier.tierFreteImpo,
          tierFreteLclExpo: tier.tierFreteLclExpo.sort((a, b) => {
            if (!a.idOrigemDestinoGrupo.id) return 1
            if (!b.idOrigemDestinoGrupo.id) return -1
            if (a.idOrigemDestinoGrupo.nome < b.idOrigemDestinoGrupo.nome) return -1
            if (a.idOrigemDestinoGrupo.nome > b.idOrigemDestinoGrupo.nome) return 1
            return 0
          }),
          tierFreteLclImpo: tier.tierFreteLclImpo.sort((a, b) => {
            if (!a.idOrigemDestinoGrupo.id) return 1
            if (!b.idOrigemDestinoGrupo.id) return -1
            if (a.idOrigemDestinoGrupo.nome < b.idOrigemDestinoGrupo.nome) return -1
            if (a.idOrigemDestinoGrupo.nome > b.idOrigemDestinoGrupo.nome) return 1
            return 0
          }),
          tierLocalExpo: tier.tierLocalExpo,
          tierLocalImpo: tier.tierLocalImpo,
          tierLocalLclExpo: tier.tierLocalLclExpo,
          tierLocalLclImpo: tier.tierLocalLclImpo
        }
      }
      _this.isLoading = false
    }).catch(() => {
      _this.isLoading = false
    })
  },
  computed: {
    getTiersLocaisPorOperacaoExpo () {
      return this.getTiersLocaisPorOperacaoTipoCarga(1, 3)
    },
    getTiersLocaisPorOperacaoImpo () {
      return this.getTiersLocaisPorOperacaoTipoCarga(2, 3)
    },
    getTiersLocaisLclPorOperacaoExpo () {
      return this.getTiersLocaisPorOperacaoTipoCarga(1, 4)
    },
    getTiersLocaisLclPorOperacaoImpo () {
      return this.getTiersLocaisPorOperacaoTipoCarga(2, 4)
    }
  },
  methods: {
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'TierPadraoIndex' })
    },
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    showNumber (number, thousandsSep, defaultValue) {
      return util.showNumber(number, thousandsSep, defaultValue)
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_tier: object.id } }).href
    },
    onSave (e) {
      var _this = this
      _this.submitted = true
      _this.isLoading = true
      _this.$validator.validateAll('frmTierPadrao').then((valid) => {
        if (valid) {
          _this.isLoading = true
          let id = this.$route.params.id
          let tierData = {
            tierFreteExpo: (_this.formData.tierFreteExpo) ? _this.formData.tierFreteExpo.id : null,
            tierFreteImpo: (_this.formData.tierFreteImpo) ? _this.formData.tierFreteImpo.id : null,
            tierFreteLclExpo: _this.formData.tierFreteLclExpo
              .filter((value) => {
                return value.ranges && value.ranges.length > 0
              })
              .map((value) => {
                let ranges = []

                for (const range of value.ranges) {
                  ranges.push({
                    cbmMinimo: range['cbmMinimo'],
                    cbmMaximo: range['cbmMaximo'],
                    valorRecebimentoUnitario: _this.$util.getNumberFromFormated(range['valorRecebimentoUnitario'])
                  })
                }

                return {
                  idOrigemDestinoGrupo: value.idOrigemDestinoGrupo.id,
                  ranges: ranges
                }
              }),
            tierFreteLclImpo: _this.formData.tierFreteLclImpo
              .filter((value) => {
                return value.ranges && value.ranges.length > 0
              })
              .map((value) => {
                let ranges = []

                for (const range of value.ranges) {
                  ranges.push({
                    cbmMinimo: range['cbmMinimo'],
                    cbmMaximo: range['cbmMaximo'],
                    valorRecebimentoUnitario: _this.$util.getNumberFromFormated(range['valorRecebimentoUnitario'])
                  })
                }

                return {
                  idOrigemDestinoGrupo: value.idOrigemDestinoGrupo.id,
                  ranges: ranges
                }
              }),
            tierLocalExpo: (_this.formData.tierLocalExpo) ? _this.formData.tierLocalExpo.id : null,
            tierLocalImpo: (_this.formData.tierLocalImpo) ? _this.formData.tierLocalImpo.id : null,
            tierLocalLclExpo: (_this.formData.tierLocalLclExpo) ? _this.formData.tierLocalLclExpo.id : null,
            tierLocalLclImpo: (_this.formData.tierLocalLclImpo) ? _this.formData.tierLocalLclImpo.id : null
          }

          // Sempre cria um novo para manter o histórico
          TierService.editTierCustomer(id, tierData).then(response => {
            _this.$router.push({ name: 'TierSettings' })
          }).catch(() => {
            _this.isLoading = false
          })
        }
      }).finally(() => {
        _this.isLoading = false
      })
    },
    getTiersLocaisPorOperacaoTipoCarga (operation, tipoCarga) {
      let _this = this
      let originsPerOperation = []

      if (operation === 1) {
        originsPerOperation = _this.tierLocalList.filter(x => {
          return (!x.hasOwnProperty('tipo_operacao') || x.tipo_operacao === 1) && (!x.hasOwnProperty('tipo_carga') || x.tipo_carga === tipoCarga)
        })
      } else {
        originsPerOperation = _this.tierLocalList.filter(x => {
          return (!x.hasOwnProperty('tipo_operacao') || x.tipo_operacao === 2) && (!x.hasOwnProperty('tipo_carga') || x.tipo_carga === tipoCarga)
        })
      }

      return originsPerOperation
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;
  }
</style>
