<template xmlns:div="http://www.w3.org/1999/html">
  <div class="col-12 no-padding pb-2">
      <div class="row form-header">
        <div class="col-9">
          {{ tier.idOrigemDestinoGrupo.nome }}
          <span v-if="!tier.idOrigemDestinoGrupo.id"><i class="ft-help-circle"></i>
            <md-tooltip md-direction="right">Tier padrão aplicado quando
              &nbsp;&nbsp;1.Origem/Destino não tiver Origem/Destino Grupo informado.
              &nbsp;&nbsp;2.Não houver tier específico informado para Origem/Destino Grupo.
            </md-tooltip>
          </span>
        </div>
        <div class="col-3 text-right no-padding">
          <div class="">
            <label class="ui-switch">Padrão<input v-on:input="tooggleCustomRanges"  :checked="customRanges" type="checkbox"><span></span>Cliente</label>
          </div>
        </div>
      </div>
      <div class="form-data" v-if="tier.ranges">
        <!-- Range -->
        <div class="col-12 col-sm-12 form-group mb-4">
          <table class="table table-hover">
            <thead>
            <tr>
              <th>
                CBM Mínimo
              </th>
              <th>
                CBM Máximo
              </th>
              <th>
                Valor Frete/WM
              </th>
            </tr>
            </thead>
            <tbody v-for="(range, index) in tier.ranges" v-bind:key="index">
            <tr>
              <td>
                {{ $util.formatNumber(range.cbmMinimo) }}
              </td>
              <td>
                {{ $util.formatNumber(range.cbmMaximo) }}
              </td>
              <td>
                <custom-input
                  v-model="tier.ranges[index].valorRecebimentoUnitario"
                  :name="'[\'ranges\'][' + index + '][\'valorRecebimentoUnitario\']'"
                  type="text"
                  rootClassName="col-sm-4 form-group m-0"
                  :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
                >
                </custom-input>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
  </div>
</template>

<script>
import CustomInput from '@/components/Forms/CustomInput.vue'

export default {
  name: 'TierSettingsFormTierRange',
  data () {
    return {
      //  Selected Values (Form)
      submitted: false
    }
  },
  props: {
    tier: {
      type: Object,
      required: true
    }
  },
  components: {
    CustomInput
  },
  // Apply filters to local filter
  computed: {
    customRanges () {
      if (this.tier.ranges) {
        return true
      }
      return false
    }
  },
  methods: {
    tooggleCustomRanges () {
      if (this.tier.ranges) {
        this.tier.ranges = null
      } else {
        this.tier.ranges = [
          {
            cbmMinimo: 0,
            cbmMaximo: 1.0,
            valorRecebimentoUnitario: null
          },
          {
            cbmMinimo: 1.01,
            cbmMaximo: 3.0,
            valorRecebimentoUnitario: null
          },
          {
            cbmMinimo: 3.01,
            cbmMaximo: 6.0,
            valorRecebimentoUnitario: null
          },
          {
            cbmMinimo: 6.01,
            cbmMaximo: 10.0,
            valorRecebimentoUnitario: null
          },
          {
            cbmMinimo: 10.01,
            cbmMaximo: 15.0,
            valorRecebimentoUnitario: null
          }
        ]
      }
    }
  },
  watch: {
    tier: function (newVal, oldVal) {
    }
  }
}
</script>

<style scoped  lang="scss">
  @function dp($_dp) {
    @return (72 / 160) * $_dp + pt;
  }

  .form-header, .form-data {
    padding: dp(16) dp(24);
    border: 1px solid #e0e0e0;
  }

  .form-data {
    border-top: 0;
  }
</style>
